import classNames from 'classnames'
import {useCallback, useState} from 'react'

import styled from 'styled-components'

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 420px;
  min-height: 160px;
  max-height: 492px;
  margin: auto;
  padding: 20px 16px;

  &.compact {
    min-height: unset;
    padding: unset;
  }
`

const CardImg = styled.img`
  width: 100%;
  height: 100%;
  max-height: 140px;
  object-fit: contain;
`

interface ImageProps {
  src: string
  alt: string
  compact?: boolean
}

export default function ProductImage({
  src,
  alt,
  compact = false,
}: ImageProps): JSX.Element {
  const [img, setImg] = useState(src || '/images/placeholder.png')

  const onError = useCallback(() => {
    setImg('/images/placeholder.png')
  }, [])

  return (
    <ImageWrapper className={classNames({compact})}>
      <CardImg src={img} alt={alt} onError={onError} />
    </ImageWrapper>
  )
}
